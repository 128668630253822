import CTAButton from "./CTAButton";
// import AndroidWaitistButton from "./AndroidWaitlistButton";
import { useEffect, useState } from "react";
import ConcertsCTAButton from "./ConcertsCTAButton";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const AppBanner = () => {
    return (
        <div className="w-full h-full flex justify-center items-center gap-2 md:gap-4">
            <div className="font-inter font-extrabold text-[9px] md:text-[24px] text-[#0E0F0C]">
                Flex your music knowledge & earn bragging rights!
            </div>
            <CTAButton />
        </div>
    )
}

const ConcertsBanner = () => {
    return (
        <div className="w-full h-full flex justify-center items-center gap-2 md:gap-4">
            <div className="font-inter font-extrabold text-[11px] md:text-[24px] text-[#0E0F0C]">
            Win Free Concert Tickets! 
            </div>
            <ConcertsCTAButton />
        </div>
    )
}

// const AndroidWaitlistBanner = () => {
//     return (
//         <div className="w-full h-full flex justify-center items-center gap-2 md:gap-4">
//             <h1 className="font-inter font-extrabold text-[11px] md:text-[24px] text-[#0E0F0C]">
//                 Join the Android Waitlist! 
//             </h1>
//             <AndroidWaitistButton />
//         </div>
//     )
// }

const Header = () => {

    const [currentIndex, setCurrentIndex] = useState(0)

    const handleLeftClick = () => {
        if (currentIndex === 0) {
            setCurrentIndex(prevCurentIndex => prevCurentIndex = 1)
        } else {
            setCurrentIndex(prevCurentIndex => prevCurentIndex - 1)
        }
    }

    const handleRightClick = () => {
        if (currentIndex === 1) {
            setCurrentIndex(prevCurentIndex => prevCurentIndex = 0)
        } else {
            setCurrentIndex(prevCurentIndex => prevCurentIndex + 1)
        }
    }

    useEffect(() => {
    const interval = setInterval(() => {
            setCurrentIndex((prevValue) => {
                if (prevValue === 1) return 0
                return prevValue + 1
            });
        }, 3000);
    
        return () => clearInterval(interval);
      }, []);

    return (
        <div className="w-screen h-[40px] md:h-[60px] fixed top-0 left-0 bg-[#FEC330] flex flex-row justify-center items-center z-20">
            <div className="w-[5%] h-[100%] flex justify-center items-center">
                <div className="hidden md:block cursor-pointer" onClick={handleLeftClick}>
                    <FaChevronLeft size={"1.5em"} color={"#000000"} />
                </div>
                <div className="block md:hidden cursor-pointer" onClick={handleLeftClick}>
                    <FaChevronLeft size={"0.75em"} color={"#000000"} />
                </div>
            </div>
            <div className="w-[90%] h-[100%] flex justify-center items-center">
                {
                    currentIndex === 0 ? <AppBanner /> : <ConcertsBanner />
                }
            </div>
            <div className="w-[5%] h-[100%] flex justify-center items-center">
                <div className="hidden md:block cursor-pointer" onClick={handleRightClick}>
                    <FaChevronRight size={"1.5em"} color={"#000000"} />
                </div>
                <div className="block md:hidden cursor-pointer" onClick={handleRightClick}>
                    <FaChevronRight size={"0.75em"} color={"#000000"} />
                </div>
            </div>
        </div>
    )
}

export default Header;
