import useGA from "../../hooks/useGA";
import { GA_EVENTS, CT_EVENTS } from "../../constants/dataConstant";
import { useNavigate } from "react-router";
import useClevertap from "../../hooks/useClevertap";

const ConcertsCTAButton = () => {

    const navigate = useNavigate()
    const { recordGAEvent } = useGA()
    const { handleEventPushClick } = useClevertap()

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        recordGAEvent(GA_EVENTS.MUZIFY_CONCERTS_CTA_CLICK)
        handleEventPushClick(CT_EVENTS.MUZIFY_CONCERTS_CTA_CLICK, {})
        navigate("/concerts")
    }

    return (
        <button onClick={(e) => handleClick(e)} className="flex justify-center items-center">
            <div className="inline-block p-[2px] rounded-full shadow-[0_2px_4px_1px_rgba(14,15,12,0.3)] bg-gradient-to-br from-[#FFEBBA] to-[#98751D]">
                <div className="py-1 md:py-2 px-2 md:px-4 rounded-full bg-[#0E0F0C] flex flex-row justify-center items-center font-inter font-semibold text-[9px] md:text-[14px] leading-[120%] text-[#FEC330]">
                    <div>Play Now</div>
                </div>
            </div>
        </button>
    )
}

export default ConcertsCTAButton;
